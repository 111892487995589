<template>
  <div class="personalInfoReported">
    <v-list
      ref="list"
      :isAdd="true"
      :border="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :operateColumnWidth="200"
      operateColumnAlign="center"
      isManySelect
    >
      <template #searchSlot>
        <v-input label="真实姓名" v-model="searchParam.realName" />
        <v-select
          label="上报状态"
          clearable
          :options="reportStatusOps"
          v-model="searchParam.status"
          @change="$refs.list.getList()"
          placeholder="请选择上报状态"
        />
        <v-select
          clearable
          label="更新状态"
          :options="updateStatusOps"
          v-model="searchParam.isUpdate"
          @change="$refs.list.getList()"
          placeholder="更新状态"
        />
        <v-select
          clearable
          label="是否上报"
          :options="isReportedStatusOps"
          v-model="searchParam.isReported"
          @change="$refs.list.getList()"
          placeholder="更新状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="editData(scope.row)" />
        <v-button
          v-if="scope.row.rfid && scope.row.loginId"
          text="取消"
          type="text"
          @click="cancelData(scope.row, false)"
        />
        <v-button
          v-if="scope.row.rfid && scope.row.loginId"
          text="删除"
          type="text"
          @click="cancelData(scope.row, true)"
        />
        <v-button text="日志" type="text" @click="goViewLog(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量上报"
          v-loading.fullscreen.lock="isLoading"
          element-loading-text="拼命上报中"
          :disabled="scope.selectedData.data.length === 0"
          @click="reportData(scope.selectedData, 1)"
        />
        <v-button
          v-loading.fullscreen.lock="isAllLoading"
          element-loading-text="拼命上报中"
          text="全部上报"
          @click="reportData(scope.selectedData, 2)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  mySpaceUrl,
  applyAddUrl,
  communityListUrl,
  authTenantListUrl,
  getReportedUserInfoList,
  getUserContrastDetails,
  cancelReportedUserConInfo,
  updateAndReport,
  reportedPeopleHouse,
  getReportedErrorLog,
} from "./api.js";

import {
  reportStatusOps,
  updateStatusOps,
  cardTypeMap,
  reportStatusMap,
  isReportedStatusOps,
} from "./map.js";
import { downloadHelper } from "@/utils/func.js";
import { _localStorage, createReportStatusHtml } from "@/utils/utils.js";
export default {
  name: "personalInfoReported",
  data() {
    return {
      isLoading: false,
      isAllLoading: false,
      searchParam: {
        tenantId: "",
        realName: "", // 真实姓名
        status: "", //上报状态
        isUpdate: "", //更新状态
        isReported: "", //是否上报
      },
      tenantId: "",
      reportStatusOps: reportStatusOps(), //上报状态
      updateStatusOps: updateStatusOps(), //更新状态
      isReportedStatusOps: isReportedStatusOps(), //是否上报
      tableUrl: getReportedUserInfoList,
      headers: [
        {
          prop: "phone",
          label: "手机号码",
          align: "center",
        },
        {
          prop: "realName",
          label: "真实姓名",
          align: "center",
        },
        {
          prop: "cardType",
          label: "身份证件类型",
          align: "center",
          formatter: (row, prop) => {
            return cardTypeMap[row[prop]];
          },
        },
        {
          prop: "cardNum",
          label: "身份证号码",
          align: "center",
        },
        {
          prop: "reportedStatus",
          label: "是否上报",
          align: "center",
          formatter: (row, prop) => {
            let opts = {};
            if (Object.prototype.hasOwnProperty.call(row, "reportedStatus")) {
              if (row[prop] == "1") {
                opts.txt = "已上报";
              } else if (row[prop] == "0") {
                opts.txt = "未上报";
              }
              return createReportStatusHtml(this, row, prop, opts);
            } else {
              return "--";
            }
          },
          show: true,
        },
        {
          prop: "lastReportedStatus",
          label: "上报状态",
          align: "center",
          formatter: (row, prop) => {
            let txt = "";
            if (row[prop] == 1) {
              txt = "成功";
            } else if (row[prop] == 2) {
              txt = "失败";
            } else if (row[prop] == 0) {
              txt = "未上报";
            } else {
              return "--";
            }
            return txt;
          },
        },
      ],
    };
  },
  created() {
    if (_localStorage.getItem("userInfo")) {
      let userInfo = JSON.parse(_localStorage.getItem("userInfo"));
      this.tenantId = userInfo.tenantId;
      this.searchParam.tenantId = userInfo.tenantId;
    }
  },
  computed: {},
  mounted() {},
  methods: {
    //编辑数据
    editData(row) {
      this.$router.push({
        name: "zZTSSPeopleInfoUpReportEdit",
        query: {
          id: row.id,
        },
      });
    },
    //取消or删除
    cancelData(row, delFlag) {
      let txt = delFlag ? "删除" : "取消";
      this.$confirm(`是否确定${txt}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          id: row.id,
          tenantId: row.tenantId,
          delFlag,
        };
        this.$axios
          .post(cancelReportedUserConInfo, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              this.$message.success(res.msg);
              this.$refs.list.getList();
            }
          });
      });
    },
    //全部上报or批量上报
    reportData(id, type) {
      let params;
      if (type == 1) {
        this.isLoading = true;
        const ids = id.ids.join(",");
        params = {
          ids: ids,
          tenantId: this.tenantId,
        };
      } else {
        this.isAllLoading = true;
        params = {
          tenantId: this.tenantId,
        };
      }
      this.reportCommonFun(params);
    },
    reportCommonFun(params) {
      this.$axios
        .post(`${reportedPeopleHouse}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.$refs.list.toggleSelection();
            this.$refs.list.getList();
            this.$message.success(res.data);
          }
        })
        .finally(() => {
          this.$refs.list.toggleSelection();
          this.isLoading = false;
          this.isAllLoading = false;
        });
    },
    //查看日志
    goViewLog(row) {
      this.$router.push({
        name: "zZTSSHouseInfoUpReportViewLogs",
        query: {
          id: row.id,
          type: 2,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.personalInfoReported {
  box-sizing: border-box;
  height: 100%;
  /deep/ .v-table .opera-btn {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
}
</style>
